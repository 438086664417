.App {
  text-align: center;
  background-color: #111111;
}
.StressCalcAppPage{
  text-align: center;
  background-color: #3dd2dc;
  height: 110%;
  margin-bottom: 0;
}

.home-contents{
  background-color:#111111;
  bottom: 50%;
}

.App-header{
  display: flex;
  align-items: center;
}

a{
	color: #D7CAF5;
}

a:hover{
	color: white;
}

.main-page-p {
  font-size: 3.5vh;
  color: white;

}

@media only screen and (min-width:961px){
.selfie{
  margin-top: 50px;
  max-width:20vh;
  clip-path: circle();
  filter: grayscale(100%);
}

.flag{
  max-width:5vh;
 
}

.book-card-grid{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  margin-left: 15vw;
}

.book-cover{
  width: 40vh;
  height: 60vh;
  margin: 5px 5px;
  border-radius: 5px;}


}


@media only screen and (max-width:961px){
.selfie{
  margin-top: 50px;
  max-width:35vw;
  clip-path: circle();
  filter: grayscale(100%);
}

.flag{
  max-width:5vh;
 
}


.book-card-grid{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;
  transform: translate(15vw);
}

.book-cover{
  width: 30vh;
  height: 50vh;
  margin: 5px 5px;
  border-radius: 5px;
}




}





.home-intro-box{
	transform: translate(0%, 0%);
}

h2{
	color: #D7CAF5;
}

.blog-title{
  color: #D7CAF5;
  font-size: 3rem;
  text-align: left;

}

.notfound{
  color:white;
  font-size: 8rem;
}

.main-page-p:hover {
  font-size: 3.5vh;
  color: white;
 
}

.main-page-sub-p{
  font-size: 2.5vh;
  color: white;
  font-family: Arial;
  margin-top: 30px;
  text-align: center;
  margin-left: 15vw;
  margin-right: 15vw;
}

.main-page-sub-contact{
  font-size: 2.3vh;
  color: white;
  font-family: Arial;
  margin-top: 30px;
  text-align: middle;
  margin-left: 25vw;
  margin-right: 25vw;
}

.blog-page-sub-p{
  font-size: 2.7vh;
  color: #D7CAF5;
  margin: 20px 20px;
  text-align: left;

}

.blog-box{
  position: absolute;
  left: 25%;

}

li{
  font-size: 2.7vh;
  color: #D7CAF5;
  margin: 20px 20px;
  text-align: left;
 
}

.main-page-p-box{
  padding-bottom:100px;
}

.main-page-sub-p-stresscalc{
   font-size: 6vmin;
   color: #797979;
   margin-top: 0rem !important;
}

.stresscalc-logo-and-header{

    display: inline-flex;
    flex-direction: row;

}

.greeting-text{
  margin: 20px 30px;
  font-family: Georgia;
  font-size: 8vh;
  font-weight: bold;
  color: white;
}

.social-box{
  font-size: 2vh;
  background-color: #181818;
  width: 100%;
  position: fixed;
  bottom: 0;

}

.navbar-style{
    background-color: #111111 !important;
    width: 100%;
    margin-top: 0px; 
    font-family: Georgia;
}

.page-title{
   margin: 4vmin 3vmin;
  font-size: 6vh;
  color: #8157E6;
}

.blog-title{
   margin: 4vmin 3vmin;
  font-size: 6vh;
  color: #8157E6;
}



.blog-last{
  margin-bottom: 200px;
}

.blog-themes-box{
  display:flex;
  justify-content: center;
  align-items: center;
}

.blog-themes{
  background-color: #8157E6;
  font-size: 1rem;
  border-radius: 20px;
  margin: 5px 5px;
  color: white;
  padding: 5px 10px;

}

.page-title-stresscalc{
    margin: 1vmin 1vmin;
    font-size: 12vmin;
    color: white;
    font-weight: bold;
}

.stresscalc-screenshots{
   margin: 0.5rem;
   width: 25vh;
   border-radius: 15px;
box-shadow: rgba(99, 99, 99, 0.5) 0px 2px 8px 0px;
}

.store-buttons{
    margin: 1vmin;

}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #121212;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.bandcamp-embed{
  border: 0;
  width: 350px;
  height: 470px;
  margin: 4px 4px 4px 4px;
}

.soundcloud-embed{
  width: 85%;
  height: 166px;
  margin: 4px 4px 4px 4px;
}

.music-subhead{
  margin: 4vmin 3vmin;
  font-size: 6vh;
  color: #D7CAF5;

}

.project-summary{
    color: #EEE
}

/* Desktop View for Project Cards*/
@media (min-width: 1400px) {
    

   .project-card-grid{
    display:flex;
    flex-wrap: wrap;
    flex: row;
    transform: translate(10vw, 0%);
    margin: 5vmin 1vmin;
    width: 80%;
}
   .project-card{
        background-color: white;
        border-radius: 10px;
        width: 50vmin;
        height: 15vh;
        position: relative;
        margin-left: 100px;


    }

    .project-summary{
       font-size: 1.5vw;
       color: #BBB;
       transform: translate(0%, 35%);
        }
}


/* MOBILE View for Project Cards*/
@media (max-width: 1400px) {
    

   .project-card-grid{
    transform: translate(10vw, 0%);
    margin: 5.5vmin 1vmin;
    width: 80%;
}
}